import { useEffect } from 'react';
import { useAuthUserLoginFromJWT, useLoad, useTranslate } from '@xFrame4/components/Hooks';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { setIsAppStartingUp } from '@redux/layoutSlice';
import AppTracker from './tracking/AppTracker';

/**
 * Handle the app state when it starts up or user logs in/out.
 */
export function useHandleAppState()
{
    const dispatch = useAppActionDispatch();
    const appState_isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);

    // log in the user fromt the JWT in the local storage
    const { isUserLoggedIn, isLoading, hasAuthRequestFinished } = useAuthUserLoginFromJWT([]);

    // set the app starting up state to finished when the auth request has finished
    useEffect(() => {
        if (hasAuthRequestFinished)
        {
            dispatch(setIsAppStartingUp(false));
        }
    }, [hasAuthRequestFinished]);

    // Initialize the AppTracker (accessing the singleton instance will initialize it)
    // This will track all page views across the site
    AppTracker.instance;
}

/** 
 * Hook: formats a Page title
 */
export function useFormatPageTitle()
{
    const t = useTranslate();

    const formatPageTitle = (title?: string|null) =>
    {
        if (title == null) title = '';
        
        return title + ' - ' + t('APP_TITLE');
    }

    return formatPageTitle;
}