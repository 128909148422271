'use client';
import { FunctionComponent } from 'react';
import { store } from '@redux/store';
import { Provider } from 'react-redux';

export interface ReduxProviderWrapperProps
{
    children: React.ReactNode;
}

/**
 * Component: 
 */
const ReduxProviderWrapper: FunctionComponent<ReduxProviderWrapperProps> = (props) =>
{
    /** Render */
    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    );
}

export default ReduxProviderWrapper;