'use client';
import { FunctionComponent } from 'react';
import { useHandleAppState } from '@components/Hooks';

export interface AppStateProps
{

}

/**
 * Component: handle the app state when it starts up or user logs in/out. This client component is needed to call the useHandleAppState hook.
 */
const AppState: FunctionComponent<AppStateProps> = (props) =>
{
    useHandleAppState();

    /** Render */
    return (
        <>
        </>
    );
}

export default AppState;