import config from '@config';
import Tracker, { TrackerEcommerceEventType, TrackerExtraUserData, TrackerProduct } from '@xFrame4/common/Tracker';

export enum AppTrackerEvent
{
    
}

export class AppTracker extends Tracker
{
    protected static createInstance(): AppTracker 
    {
        return new AppTracker();    
    }

    // override
    protected async init()
    {
        this.lazyLoadScripts = true;
        this.trackWithGoogle = true;
        this.trackWithFacebook = true;
        this.trackWithTikTok = true;

        super.init();
    }
}

export default AppTracker;